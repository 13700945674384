import { getConfig } from '../../config.js'

const overrideStorageKey = 'cx-docs-overrides'
const devToolsStorageKey = 'cx-docs-devtools'

function proxyUrl(url) {
  const config = getConfig()
  return `${config.docsApi}/proxy?path=${url}`
}

export function isDevToolsEnabled() {
  return localStorage.getItem(devToolsStorageKey) === 'true'
}

const getLocalStorage = () => {
  const local = localStorage.getItem(overrideStorageKey)
  const overrides = JSON.parse(local || '{}')
  return overrides
}

const getOverrides = (projectName) => {
  const overrides = getLocalStorage()
  return overrides[projectName] || {}
}

const setOverrides = (projectName, data) => {
  const overrides = getLocalStorage()

  localStorage.setItem(
    overrideStorageKey,
    JSON.stringify({
      ...overrides,
      [projectName]: data,
    })
  )
}

export const setDocOverride = (projectName, docId, payload) => {
  const overrides = getOverrides(projectName)

  if (payload) {
    overrides[docId] = {
      ...payload,
      href: proxyUrl(payload.url),
    }
  } else {
    delete overrides[docId]
  }

  setOverrides(projectName, overrides)
}

export const getDocOverride = (projectName, docId) => {
  if (!isDevToolsEnabled()) return
  const overrides = getOverrides(projectName)
  return overrides[docId]
}

export const clearProjectOverrides = (projectName) => {
  const overrides = getLocalStorage()
  delete overrides[projectName]
  localStorage.setItem(overrideStorageKey, JSON.stringify(overrides))
}
