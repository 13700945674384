import React, { useState, useEffect } from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

import { parse as apiParse } from '../../util/api-parser.js'
import Error from '../Error/Error.js'
import { ModelCollapseOverride } from './plugins.js'

/**
 * The deepLink functionality in SwaggerUI lib does not work as expected.
 * The section opens correctly, but the page does not scroll to the correct location.
 * parseDeepLinkHash is copied from the SwaggerUI source code and modified to only return the id.
 */
const parseDeepLinkHash = (system, rawHash) => {
  if (rawHash) {
    let hash = rawHash.slice(1) // # is first character

    if (hash[0] === '!') {
      // Parse UI 2.x shebangs
      hash = hash.slice(1)
    }

    if (hash[0] === '/') {
      // "/pet/addPet" => "pet/addPet"
      // makes the split result cleaner
      // also handles forgotten leading slash
      hash = hash.slice(1)
    }

    const hashArray = hash.split('/').map((val) => val || '')
    const isShownKey = system.layoutSelectors.isShownKeyFromUrlHashArray(hashArray)
    const [type, tagId = '', maybeOperationId = ''] = isShownKey

    const id = [type, tagId, maybeOperationId].join('-')
    return id
  }
}

function SwaggerDocs({ spec, onError }) {
  const [apiSpec, setApiSpec] = useState(null)
  const [error, setError] = useState(null)

  function handleOnComplete(system) {
    const id = parseDeepLinkHash(system, window.location.hash)
    document.getElementById(id)?.scrollIntoView()
  }

  useEffect(() => {
    try {
      const parsed = apiParse(spec)
      if (!parsed) {
        throw new Error('Failed to parse API Spec')
      }
      setApiSpec(parsed)
    } catch (e) {
      // If onError supplied, do not supply default error component
      if (onError) {
        onError(e)
      } else {
        setError('Error Parsing Spec' + e.toString())
      }
      return
    }
  }, [spec])

  if (error) return <Error error={error} />
  if (!apiSpec) return null

  return (
    <SwaggerUI
      spec={spec}
      deepLinking={true}
      plugins={[ModelCollapseOverride]}
      presets={[SwaggerUI.presets.apis]}
      onComplete={handleOnComplete}
    />
  )
}

export default SwaggerDocs
