import React from 'react'
import { Icon, Banner } from '@nike/eds'

import styles from '../../stylus/devtools.styl'

export default function OverrideWarningBanner() {
  return (
    <Banner
      className={`${styles.overrideBanner} eds-banner eds-banner--danger`} // TODO: this component clears the eds styles if i provide a classname prop
      customIcon={<Icon name='AlertCircle' size='m' />}
      status='danger'
    >
      This page is currently using an override URL source. Check the Docs DevTools to view or remove
      the override.
    </Banner>
  )
}
