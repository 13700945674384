import React, { useCallback, useState, useEffect } from 'react'
import Asciidoctor from 'asciidoctor'
import createDocPath from '../../util/createDocPath.js'
import { scrollHashIntoView } from '../../util/scrollIntoView.js'
import { getDocumentImageBase64 } from './imageLoader.js'
import { getConfig } from '../../config.js'

const Ascii = Asciidoctor.default || Asciidoctor

function checkTreeForLink(xref, content, basePath) {
  const tree = content.tree
  let xrefPath
  for (const prop in tree) {
    if (tree[prop].sourceUrl) {
      const sourceUrl = tree[prop].sourceUrl
      const replaceableXref = xref.str.slice(xref.str.indexOf('xref:'), xref.str.indexOf('['))

      let parsedXref = xref.str
        .slice(xref.str.indexOf('xref:') + 5, xref.str.indexOf('['))
        .replace(/\.+\//g, '') // remove any path parts to conver it to an absolute path

      parsedXref = parsedXref.includes(window.location.pathname.split('/')[1])
        ? parsedXref.slice(parsedXref.indexOf(':') + 1)
        : parsedXref

      if (sourceUrl.endsWith(parsedXref.replace(/:/g, '/'))) {
        xrefPath = xref.str
          .replace(replaceableXref, createDocPath(tree[prop], basePath, true))
          .replace(/ /g, '%20')
      } else {
        const arr = parsedXref.replace(/:/g, '/').split('/')
        arr.splice(1, 0, 'pages')
        const pagesPath = arr.join('/')
        if (sourceUrl.endsWith(pagesPath)) {
          xrefPath = xref.str
            .replace(replaceableXref, createDocPath(tree[prop], basePath, true))
            .replace(/ /g, '%20')
        }
      }
    }
  }

  return {
    str: xrefPath,
    index: xref.index,
  }
}

function replaceXrefs(content, basePath) {
  const splitContent = content.raw.split(' ')
  let xrefs = splitContent
    .map((xref, index) => ({ str: xref, index }))
    .filter((xref) => xref.str.includes('xref:'))

  xrefs = xrefs.map((xref) => checkTreeForLink(xref, content, basePath))
  xrefs.forEach((xref) => {
    splitContent[xref.index] = xref.str
  })

  return splitContent.join(' ')
}

async function mapNewImage(getImageData, image) {
  const asciiImagePath = image.str.slice(0, image.str.indexOf('['))
  const stringToReplace = asciiImagePath.includes('image::') ? 'image::' : 'image:'

  const imagePathUrl = asciiImagePath
    .replace(stringToReplace, '')
    .slice(asciiImagePath.indexOf(stringToReplace))

  const imageData = await getImageData(imagePathUrl)
  const url = setImageSrc(imageData, imagePathUrl)
  return url
}

function setImageSrc(imgData, imageName) {
  try {
    const base64Flag = 'data:image/png;base64,'
    const img = document.querySelector(`img[src='${imageName}']`)
    if (!imgData || !img) {
      return
    }
    img.src = base64Flag + imgData
    const parentClassList = img.parentNode.classList
    const grandParentClassList = img.parentNode.parentNode.classList
    img.onload = () => {
      parentClassList.remove('image')
      parentClassList.add('imageshow')
      grandParentClassList.remove('imageblock')
      grandParentClassList.add('imageblockshow')
    }
  } catch (err) {
    console.error(err)
  }
}

function replaceImages(getImageData, content) {
  const splitContent = content.split(' ')
  const images = splitContent
    .map((str, index) => ({ str, index }))
    .filter((image) => image.str.includes('image:'))

  const filteredImages = images.map((image) => mapNewImage(getImageData, image))

  filteredImages.forEach((image) => {
    splitContent[image.index] = image.str
  })

  return splitContent.join(' ')
}

export default function AsciiDoc({
  className,
  style,
  outerTagName,
  content,
  onNavigate,
  basePath,
}) {
  const asciidoctor = Ascii()
  const OuterElement = outerTagName || 'div'
  const [currentHash, setCurrentHash] = useState(window.location.hash)
  const config = getConfig()
  const docsApi = config.docsApi

  useEffect(() => {
    // React-markdown seems to have some async rendering
    // causing this to fail in many cases when run synchronously
    setTimeout(() => scrollHashIntoView(currentHash), 10)
  }, [content, currentHash])

  function onLocationChange(e) {
    onNavigate(e)
    if (e.target?.hash) {
      setCurrentHash(e.target.hash)
    }
  }

  useEffect(() => {
    const wrapper = document.querySelector('.EP-asciidoc-content')
    if (wrapper) {
      wrapper.addEventListener('click', onLocationChange)
      return () => wrapper.removeEventListener('click', onLocationChange)
    }
  }, [])

  if (!content.raw) return 'Content not found'

  const { version, projectName, title } = content

  const getImageData = useCallback(
    async (imageName) => {
      if (!version || !projectName || !title || !imageName) return ''
      return getDocumentImageBase64(version, projectName, title, imageName, docsApi) || ''
    },
    [version, projectName, title]
  )

  // Parses Xrefs to usable hrefs for this site
  let renderContent = content.raw.includes('xref:') ? replaceXrefs(content, basePath) : content.raw

  // Parses image names from content and fetches image data from API
  // does not block content from rendering, images will
  renderContent = renderContent.includes('image:')
    ? replaceImages(getImageData, renderContent)
    : renderContent

  const __html = asciidoctor.convert(`:sectanchors:\n:sectlinks:\n${renderContent}`, {
    attributes: { showtitle: true },
  })

  return <OuterElement className={className} style={style} dangerouslySetInnerHTML={{ __html }} />
}
