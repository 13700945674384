import styles from './stylus/docs.styl'
import React, { useState, useEffect, useRef } from 'react'
import { Snackbar, Snack } from '@nike/eds'
import qs from 'qs'
import urlJoin from 'url-join'

import { setConfig } from './config.js'
import { setGlobalHeader } from './util/http.js'
import { authDAMSession } from './util/dam.js'
import NarkDocs from './util/nark.js'
import { sendEmailRequest } from './util/sendEmail.js'
import { fetchProject } from './util/devportal.js'

import Tree from './components/Tree/Tree.js'
import DocsContent from './components/Content/Content.js'
import Toolbar from './components/Toolbar/Toolbar.js'
import { getNodeBySlug } from './components/Tree/selectors.js'

import TerraformIcon from '../assets/svg-icons/terraform-icon.svg'
import SdkIcon from '../assets/svg-icons/sdk-icon.svg'
import CliIcon from '../assets/svg-icons/cli-icon.svg'
import GitHubIcon from '../assets/svg-icons/github.svg'
import Links, { LinksDropdown } from './components/Toolbar/Links.js'
import ReportableLink from './components/ReportableLink/ReportableLink.jsx'

const Documentation = (props) => {
  const {
    token,
    basePath,
    projectName,
    showBreadCrumbs,
    favoriteButton,
    Link,
    hasTopNav,
    env,
  } = props

  const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false)
  const nark = token ? NarkDocs(token) : undefined

  const onReportLink = async (linkData) => {
    if (linkData) {
      nark?.log({
        operation: 'reportBrokenLink',
        feature: 'reportableLink',
        data: {
          page: linkData.currentPage,
          href: linkData.href,
          text: linkData.text,
          to: linkData.adminEmail,
          name: projectName,
        },
      })
      await sendEmailRequest(linkData, token)
    }
  }

  const onFeedbackSubmit = async (feedback) => {
    if (feedback) {
      nark?.log({
        operation: 'feedback',
        feature: 'documentation.feedback',
        data: {
          rating: feedback.rating,
          feedback: feedback.feedback,
          documentUrl: feedback.documentUrl,
          name: projectName,
        },
      })
    }
  }

  const config = setConfig(env)
  const docsApi = config.docsApi
  const contentRef = useRef()
  const adminEmail = useRef()
  const linksContainerRef = useRef()
  const isReportEnabledRef = useRef(false)
  const setIsReportEnabled = (value) => (isReportEnabledRef.current = value)
  const [disabled, setDisabled] = useState(false)

  const onReportClick = () => {
    contentRef.current.reportLink()
  }

  if (!projectName) {
    throw new Error('Must supply Project Name')
  }

  setGlobalHeader('Authorization', 'Bearer ' + token)

  // Parse version from qs
  const { version } = qs.parse(window.location.search.slice(1))

  // Get path accoriding to basePath provided as prop
  const path = window.location.pathname.includes(basePath)
    ? window.location.pathname.slice(basePath.length)
    : window.location.pathname

  // Split path into breadcrumbs, this array of breadcrumbs (strings)
  // is essential for each of the 3 components
  const pathCrumbs = path
    .split('/')
    .slice(1)
    .filter((crumb) => !!crumb)
    .map((crumb, i, array) => {
      // manually create the slug for the node based on the url
      const nodePath = array.slice(0, i + 1).join('::')
      return decodeURIComponent(nodePath)
    })

  // Local storage setting for side-tree open/close state
  const storageCollapsed = window.localStorage.getItem(config.treeCollapseLocalStorage)

  // Storing two versions of the tree in state because
  // having each structure available makes certain things easier

  // This is useful for converting tree data into structure needed
  // for the Tree view library (Treebeard)
  const [hierarchyTree, setHierarchyTree] = useState(null)

  // Flat tree is just storing the tree exactly how it comes
  // from the API, necessary for easy title lookup
  const [flatTree, setFlatTree] = useState(null)

  const [versions, setVersions] = useState({})
  const [selectedVersion, setSelectedVersion] = useState(version || null)
  const [contacts, setContacts] = useState([])
  const [collapsed, setCollapsed] = useState(
    storageCollapsed ? storageCollapsed !== 'false' : false
  )
  const [error, setError] = useState(null)

  // Fetches Tree, tree data to state
  useEffect(() => {
    const fetchData = async () => {
      let response
      try {
        response = await fetchProject(projectName)
      } catch (error) {
        return setError(error.message)
      }

      if (error) setError(null)

      adminEmail.current = response.maintainerEmail

      setContacts(
        [
          { type: 'cli', href: response.cliUrl, src: CliIcon, label: 'CLI' },
          { type: 'github', href: response.repositoryUrl, src: GitHubIcon, label: 'GitHub' },
          {
            type: 'terraform',
            href: response.terraformUrl,
            src: TerraformIcon,
            label: 'Terraform',
          },
          { type: 'sdk', href: response.sdkUrl, src: SdkIcon, label: 'SDK' },
        ].filter((contact) => !!contact.href)
      )

      setVersions(response.versions)
      if (!selectedVersion) {
        setSelectedVersion(response.latestVersion)
      }
    }
    fetchData()
  }, [projectName])

  useEffect(() => {
    setGlobalHeader('Authorization', 'Bearer ' + token)
    authDAMSession()
  }, [token])

  // Sets Version on update to avilable versions
  // This is integral to how initial content gets set
  useEffect(() => {
    if ((version || selectedVersion) && versions && Object.keys(versions).length) {
      const versionToSet =
        version && versions[version] ? versions[version] : versions[selectedVersion]

      const docs =
        versionToSet &&
        versionToSet.documents &&
        Object.fromEntries(
          Object.entries(versionToSet.documents).map(([title, node]) => [title, { ...node, title }])
        )
      if (!docs) {
        return setError(
          'Project does not contain document set.  Please check that the selected version is valid.'
        )
      }
      const data = createHierarchy(docs)
      setSelectedVersion(versionToSet.versionName)
      setFlatTree(docs)
      setHierarchyTree(data)
      setError(null)
    }
  }, [selectedVersion, versions, version])

  // BUILD
  const crumbs = createCrumbLinks(flatTree, pathCrumbs, props)
  return (
    <div
      className={`${styles['docs-page']} ${hasTopNav ? styles.hasTopNav : ''} ${
        collapsed ? styles['tree-collapsed'] : ''
      }`}
      ref={linksContainerRef}
    >
      <Tree
        {...props}
        version={selectedVersion}
        error={error}
        tree={!error && hierarchyTree}
        pathCrumbs={pathCrumbs}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        docsApi={docsApi}
        contacts={contacts}
      />
      <div className={styles['docs-body']}>
        {showBreadCrumbs && !error && !!Object.keys(versions).length && (
          <div className={styles.topBarContainer}>
            <div className={styles.topBar}>
              <div className={styles.crumbs}>
                <Links links={crumbs} RouterLink={Link} />
              </div>
              <div className={styles.crumbsDropdown}>
                <LinksDropdown onNavigate={props.onNavigate} links={crumbs} />
              </div>
              <Toolbar
                {...props}
                disabled={disabled}
                setDisabled={setDisabled}
                selectedVersion={selectedVersion}
                setSelectedVersion={setSelectedVersion}
                versions={versions}
                crumbs={crumbs}
                contacts={contacts}
                favoriteButton={favoriteButton}
                onReportClick={onReportClick}
                onReportLink={onReportLink}
                onFeedbackSubmit={onFeedbackSubmit}
                setDisplayThankYouMessage={setDisplayThankYouMessage}
              />
            </div>
          </div>
        )}
        <DocsContent
          {...props}
          error={error}
          tree={flatTree}
          crumbs={pathCrumbs}
          version={selectedVersion}
          nark={nark}
        />
        <ReportableLink
          contentRef={contentRef}
          linksContainerRef={linksContainerRef}
          adminEmail={adminEmail}
          onReportLink={onReportLink}
          isReportEnabled={isReportEnabledRef.current}
          setIsReportEnabled={setIsReportEnabled}
          setDisabled={setDisabled}
        />
      </div>
      <Snackbar>
        {displayThankYouMessage && (
          <Snack
            autoDismissDuration={2000}
            status='success'
            id='feedbackId'
            onDismiss={() => setDisplayThankYouMessage(false)}
          >
            Thank you for your feedback!
          </Snack>
        )}
      </Snackbar>
    </div>
  )
}

// Converts Flat data into Parent/Children structure
function createHierarchy(obj) {
  const keys = Object.keys(obj)
  const nodes = Object.values(obj).map((val, i) => ({ ...val, id: keys[i] }))

  const result = {}
  const output = {}

  nodes.forEach((a) => {
    a.children = output[a.id] && output[a.id].children
    output[a.id] = a
    if (!a.parent) {
      result[a.id] = a
    } else {
      output[a.parent] = output[a.parent] || {}
      output[a.parent].children = output[a.parent].children || []
      output[a.parent].children.push(a)
    }
  })

  return Object.values(result)
}

function createCrumbLinks(
  tree,
  pathCrumbs,
  { basePath, homeBreadCrumbText, homeBreadCrumbPath, projectAlias, projectName }
) {
  if (!tree) return []

  const crumbs = pathCrumbs
    .map((path, i) => {
      const node = getNodeBySlug(tree, path)
      if (!node) return
      return {
        title: node.docTitle,
        href: node.type === 'heading' ? undefined : urlJoin(basePath, `/${node.slug}`),
      }
    })
    .filter(Boolean)

  // Project Crumb
  crumbs.unshift({ title: projectAlias || projectName.replace(/-/g, ' '), href: basePath })
  // Optional home crumb
  if (homeBreadCrumbPath) crumbs.unshift({ title: homeBreadCrumbText, href: homeBreadCrumbPath })
  return crumbs
}

Documentation.defaultProps = {
  token: '',
  basePath: '',
  showBreadCrumbs: true,
}

export default Documentation
