import React, { useState } from 'react'
import {
  Text,
  Table,
  TableHeading,
  TableCell,
  StatusIndicator,
  IconButton,
  ButtonGroup,
  Button,
  TextField,
  Modal,
  Select,
} from '@nike/eds'

import {
  isDevToolsEnabled,
  getDocOverride,
  setDocOverride,
  clearProjectOverrides,
} from './overrides'
import styles from '../../stylus/devtools.styl'

const headers = [
  {
    Header: 'Status',
  },
  {
    Header: 'Title',
  },
  {
    Header: 'Source URL',
  },
]

const fileTypeOptions = [
  { value: 'appSync', label: 'AppSync' },
  { value: 'asciidoc', label: 'AsciiDoc' },
  { value: 'graphql', label: 'GraphQL' },
  { value: 'jupyter', label: 'Jupyter Notebook' },
  { value: 'markdown', label: 'Markdown' },
  { value: 'openapi', label: 'OpenAPI' },
  { value: 'redoc', label: 'Redoc' },
]

function getStatus(overrideUrl) {
  return {
    status: overrideUrl ? 'danger' : 'inactive',
    label: overrideUrl ? 'Override' : 'Default',
  }
}

function OverrideModal({ projectName, document, visible, toggleFn }) {
  if (!document) {
    return null
  }

  const { url: overrideUrl, fileType: overrideFileType } =
    getDocOverride(projectName, document.id) || {}
  const [docOverrideUrl, setDocOverrideUrl] = useState(overrideUrl)
  const [fileType, setFileType] = useState(overrideFileType)

  function handleOverrideClick() {
    setDocOverride(projectName, document.id, {
      url: docOverrideUrl,
      fileType,
    })
    toggleFn(false)
  }

  function handleClearClick() {
    setDocOverride(projectName, document.id, null)
    toggleFn(false)
  }

  function handleCancelClick() {
    toggleFn(false)
  }
  return (
    <Modal
      isOpen={visible}
      hideFade
      onDismiss={() => toggleFn(false)}
      headerSlot={'Override Document Source URL'}
      footerSlot={
        <ButtonGroup>
          <Button
            disableRipple
            data-external-id={'doc-override-confirm'}
            onClick={handleOverrideClick}
            disabled={!docOverrideUrl || !fileType}
          >
            Save Override
          </Button>
          <Button
            disableRipple
            data-external-id={'doc-override-cancel'}
            variant='secondary'
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          {overrideUrl && (
            <div className='eds-flex eds-flex--grow-1 eds-flex--justify-content-flex-end'>
              <Button
                className='eds-flex--item-justify-end'
                disableRipple
                data-external-id={'doc-override-clear'}
                onClick={handleClearClick}
              >
                Clear Override
              </Button>
            </div>
          )}
        </ButtonGroup>
      }
    >
      <div className='eds-grid eds-gap--12'>
        <div>
          <Text font='body-2'>
            Change the source URL of a document. This is useful for testing or previewing content.
          </Text>
          <Text font='subtitle-1'>
            You must reload the page after setting an override URL to see the changes.
          </Text>
        </div>

        <div>
          <Text font='title-6'>Document Title</Text>
          <Text font='body-2'>{document.docTitle}</Text>
        </div>
        <div>
          <Text font='title-6'>Default URL</Text>
          <Text font='body-2'>{document.sourceUrl}</Text>
        </div>
        <TextField
          label='Override URL'
          value={docOverrideUrl}
          onChange={(e) => setDocOverrideUrl(e.target.value)}
        />
        <Select
          label='File Type'
          options={fileTypeOptions}
          menuPosition={'fixed'}
          defaultValue={fileTypeOptions.find((option) => option.value === overrideFileType)}
          onChange={({ value }) => setFileType(value)}
        />
      </div>
    </Modal>
  )
}

function ClearOverridesConfirm({ visible, onConfirmClick, onCancelClick }) {
  return (
    <Modal
      isOpen={visible}
      hideFade
      onDismiss={onCancelClick}
      headerSlot={'Clear Document Source URLs'}
      footerSlot={
        <ButtonGroup>
          <Button onClick={onConfirmClick}>Confirm</Button>
          <Button variant='secondary' onClick={onCancelClick}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <Text font='body-2'>
        Are you sure you want to clear all document override URLs? Project document URLs will revert
        to their original source.
      </Text>
    </Modal>
  )
}

function DocRow({ projectName, parentTitle, onClick, ...doc }) {
  const { id, docTitle, sourceUrl, children } = doc
  const { url: overrideUrl } = getDocOverride(projectName, id) || {}
  const { status, label } = getStatus(overrideUrl)

  return (
    <>
      <tr className={styles.documentRow} key={id} onClick={() => onClick(doc)}>
        <TableCell>
          <StatusIndicator label={label} size='large' status={status} />
        </TableCell>
        <TableCell>
          {parentTitle ? parentTitle + ' > ' : null}
          {docTitle}
        </TableCell>
        <TableCell>{overrideUrl ? overrideUrl : sourceUrl}</TableCell>
      </tr>

      {children?.map((child) => (
        <DocRow
          {...child}
          projectName={projectName}
          onClick={onClick}
          parentTitle={parentTitle ? `${parentTitle} > ${doc.docTitle}` : doc.docTitle}
        />
      ))}
    </>
  )
}

function OverridePanel({ onCloseClick, projectName, hierarchyTree }) {
  const [document, setDocument] = useState(null)
  const [modalOpen, setOverrideModalOpen] = useState(false)
  const [clearAllModalOpen, setClearOverrideModalOpen] = useState(false)

  function handleDocumentClick(doc) {
    setDocument(doc)
    setOverrideModalOpen(true)
  }

  function handleResetClick() {
    setClearOverrideModalOpen(true)
  }

  function handleConfirmClick() {
    clearProjectOverrides(projectName)
    setClearOverrideModalOpen(false)
  }

  const nodes = hierarchyTree || []
  return (
    <div className={styles.devtools}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <Text font={'title-3'}>Docs Dev Tools</Text>
          <IconButton icon='Close' size='small' variant='ghost' onClick={() => onCloseClick()} />
        </div>
        <div>
          <Button onClick={handleResetClick}>Clear Overrides</Button>
        </div>
        <Table>
          <thead>
            <tr>
              {headers.map((column) => (
                <TableHeading>{column.Header}</TableHeading>
              ))}
            </tr>
          </thead>
          <tbody>
            {nodes.map((row) => (
              <DocRow {...row} projectName={projectName} onClick={handleDocumentClick} />
            ))}
          </tbody>
        </Table>
        {document && modalOpen && (
          <OverrideModal
            visible={modalOpen}
            toggleFn={setOverrideModalOpen}
            document={document}
            projectName={projectName}
          />
        )}
      </div>
      <ClearOverridesConfirm
        visible={clearAllModalOpen}
        onConfirmClick={handleConfirmClick}
        onCancelClick={() => setClearOverrideModalOpen(false)}
      />
    </div>
  )
}

function DevToolsTrigger({ onTriggerClick }) {
  return (
    <div className={styles.devToolsTrigger}>
      <IconButton icon='Settings' size='small' variant='ghost' onClick={onTriggerClick} />
    </div>
  )
}

export default function DevTools({ projectName, hierarchyTree }) {
  if (!isDevToolsEnabled()) {
    return
  }

  const [isDevToolsOpen, setDevToolsOpen] = useState(false)

  function handlePanelOpen() {
    setDevToolsOpen(true)
  }

  function handlePanelClose() {
    setDevToolsOpen(false)
  }
  return (
    <>
      <DevToolsTrigger onTriggerClick={handlePanelOpen} />
      {isDevToolsOpen && (
        <OverridePanel
          onCloseClick={handlePanelClose}
          projectName={projectName}
          hierarchyTree={hierarchyTree}
        />
      )}
    </>
  )
}
