import qs from 'qs'

const globalHeaders = {}

export function setGlobalHeader(name, value) {
  globalHeaders[name] = value
}

export function setGlobalAuthHeader(token) {
  setGlobalHeader('Authorization', 'Bearer ' + token)
}

function processError(r, contentType) {
  if (contentType === 'application/json') {
    return r.json().then((res) => {
      const error = new Error(res ? res.message : r.status)
      error.status = r.status
      throw error
    })
  }
  throw new Error()
}

function processResponse(r) {
  const contentType = r.headers.get('content-type')
  if (!r.ok) {
    return processError(r, contentType)
  }
  if (contentType?.includes('application/json')) {
    return r.json()
  } else if (contentType?.includes('text/plain')) {
    return r.text()
  } else if (contentType?.includes('application/octet-stream')) {
    return r.blob()
  } else if (contentType?.includes('image/')) {
    return r.arrayBuffer()
  }
  return r
}

export default {
  post(url, payload, headers, config) {
    return fetch(url, {
      method: 'POST',
      headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
      mode: 'cors',
      body: JSON.stringify(payload),
      ...config,
    }).then(processResponse)
  },
  get(url, query, headers) {
    if (query) {
      const queryString = qs.stringify(query)
      url = url + '?' + queryString
    }

    return fetch(url, {
      headers: Object.assign(globalHeaders, headers),
    }).then(processResponse)
  },
  getImage(url, query) {
    if (query) {
      const queryString = qs.stringify(query)
      url = url + '?' + queryString
    }

    return fetch(url, {
      headers: Object.assign(globalHeaders, { Accept: 'image/png' }),
    }).then(processResponse)
  },
  delete(url) {
    return fetch(url, {
      method: 'DELETE',
      headers: globalHeaders,
    }).then(processResponse)
  },
  put(url, payload, headers) {
    return fetch(url, {
      method: 'PUT',
      headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
      body: JSON.stringify(payload),
    }).then(processResponse)
  },
}
