import '@asyncapi/react-component/styles/default.min.css'
import React from 'react'
import AsyncApiComponent from '@asyncapi/react-component'

const defaultConfig = {
  schemaID: 'custom-spec',
  show: {
    operations: false,
    errors: true,
  },
}

export function AsyncApi({ schema, config = defaultConfig }) {
  return <AsyncApiComponent schema={schema} config={config} />
}
