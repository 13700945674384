// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iEg2pnDUA64I8YxnR6IQ{z-index:99;height:500px;position:absolute;left:1px;right:1px;bottom:0;background:var(--eds-color-background-secondary);border:2px solid var(--eds-color-border-primary);padding:20px;overflow:auto}.iEg2pnDUA64I8YxnR6IQ .cb0gNlqcAqDXhSD0yRku{display:grid;grid-row-gap:20px}.iEg2pnDUA64I8YxnR6IQ .wYrSDIJPl7Z7ECijWabU{display:flex;justify-content:space-between}.iEg2pnDUA64I8YxnR6IQ .D8NIMzLR7_redJnLjPpA{cursor:pointer}.lUHdTV_7wM4YFtN6J4jw{background:var(--eds-color-grey-2);position:absolute;bottom:20px;left:120px;z-index:1;border-radius:5px;padding:5px}.opboAi5FkzBGpicx6ZIQ div[class*='eds-banner__left-content']{align-items:center}`, "",{"version":3,"sources":["webpack://./src/stylus/devtools.styl"],"names":[],"mappings":"AACA,sBACE,UAAQ,CACR,YAAO,CACP,iBAAS,CACT,QAAK,CACL,SAAM,CACN,QAAO,CACP,gDAAW,CACX,gDAAO,CACP,YAAQ,CACR,aAAS,CACT,4CACE,YAAQ,CACR,iBAAa,CACf,4CACE,YAAQ,CACR,6BAAgB,CAClB,4CACE,cAAO,CAEX,sBACE,kCAAW,CACX,iBAAS,CACT,WAAO,CACP,UAAK,CACL,SAAQ,CACR,iBAAc,CACd,WAAQ,CAIR,6DACC,kBAAY","sourcesContent":["\n.devtools\n  z-index 99\n  height 500px\n  position absolute\n  left 1px\n  right 1px\n  bottom 0px\n  background var(--eds-color-background-secondary)\n  border 2px solid var(--eds-color-border-primary)\n  padding 20px\n  overflow auto\n  .inner\n    display grid\n    grid-row-gap 20px\n  .header\n    display flex\n    justify-content space-between\n  .documentRow\n    cursor pointer\n\n.devToolsTrigger\n  background var(--eds-color-grey-2)\n  position absolute\n  bottom 20px\n  left 120px\n  z-index 1\n  border-radius 5px\n  padding 5px\n\n.overrideBanner\n  // icon is not centered with text\n  div[class*='eds-banner__left-content']\n\t  align-items center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"devtools": `iEg2pnDUA64I8YxnR6IQ`,
	"inner": `cb0gNlqcAqDXhSD0yRku`,
	"header": `wYrSDIJPl7Z7ECijWabU`,
	"documentRow": `D8NIMzLR7_redJnLjPpA`,
	"devToolsTrigger": `lUHdTV_7wM4YFtN6J4jw`,
	"overrideBanner": `opboAi5FkzBGpicx6ZIQ`
};
export default ___CSS_LOADER_EXPORT___;
