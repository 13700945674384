import React from 'react'
import { Icon } from '@nike/eds'

import Markdown from '../Markdown/Markdown.js'
import Asciidoc from '../Ascii/Ascii.js'
import Notebook from '../Notebook/Notebook.js'
import { Redoc } from '../Redoc/Redoc.js'
import Graphiql from '../GQL/GQL.js'
import SwaggerUI from '../Swagger/Swagger.js'

import { navigate } from '../../util/navigate.js'
import createDocPath from '../../util/createDocPath.js'
import { getConfig } from '../../config.js'
import { slugify } from '../../util/slugify.js'

import styles from '../../stylus/docs.styl'

export function renderDocType({
  content,
  onNavigate,
  basePath,
  errorClassName,
  getImageData,
  token,
  contentElRef,
  Link,
  isContextualHelp,
}) {
  let className = styles['docs-content']
  let renderContent

  switch (content.type) {
    case 'jupyter':
      if (!content.raw) {
        renderContent = (
          <div className={errorClassName}>No content available for Jupyter Notebook</div>
        )
      }
      renderContent = <Notebook html={content.raw.html} />
      break
    case 'openapi':
      className = `${styles['docs-content']} ${styles['docs-swagger']}`
      renderContent = <SwaggerUI spec={content.raw} />
      break
    case 'redoc':
      className = `${styles['docs-content']} ${styles['docs-redoc']}`
      renderContent = <Redoc spec={content.raw} />
      break
    case 'asciidoc':
      className = `${styles.asciidoc} ${className}`
      // className below should be global!
      renderContent = (
        <div className='EP-asciidoc-content'>
          <Asciidoc
            basePath={basePath}
            onNavigate={navigate(onNavigate)}
            content={content}
            getImageData={getImageData}
          />
        </div>
      )
      break
    case 'appSync':
    case 'graphql':
      className = `${styles['docs-graphql']} ${className}`
      if (!(content.raw && content.raw.sourceUrl)) {
        renderContent = <div className={errorClassName}>No Source URL found for document</div>
      } else {
        renderContent = (
          <Graphiql
            token={token}
            apiUrl={content.raw.sourceUrl}
            includeBearer={!(content.type === 'appSync')}
          />
        )
      }
      break
    case 'href':
      return (
        <div className={`${styles.markdown} EP-md-content ${className}`}>
          <h1>{content.raw.docTitle}</h1>
          <a href={content.raw.sourceUrl} target='_blank' rel='noopener noreferrer'>
            {content.raw.sourceUrl}
          </a>
        </div>
      )
    default:
      className = `${styles.markdown} EP-md-content ${className}`
      renderContent = (
        <Markdown
          onNavigate={navigate(onNavigate)}
          basePath={basePath}
          components={{
            a: createLinkRenderer(content.tree, basePath, Link, isContextualHelp),
            h1: createHeadingRenderer(isContextualHelp),
            h2: createHeadingRenderer(isContextualHelp),
            h3: createHeadingRenderer(isContextualHelp),
            h4: createHeadingRenderer(isContextualHelp),
            h5: createHeadingRenderer(isContextualHelp),
            h6: createHeadingRenderer(isContextualHelp),
          }}
          content={content}
          source={content.raw}
          showNav={isContextualHelp}
        />
      )
  }

  return (
    <div ref={contentElRef} className={className}>
      {renderContent}
    </div>
  )
}

function rawToGithubUrl(rawContentUrl) {
  const { pathname } = new URL(rawContentUrl)
  // [org, repo, branch, ...rest]
  const pathParts = pathname.substring(1).split('/')
  pathParts.splice(2, 0, 'blob')
  const newPath = pathParts.join('/')
  return `https://github.com/${newPath}`
}

// Renders new tab links if absolute path in href
function createLinkRenderer(tree, basePath, Link) {
  const config = getConfig()
  return function(props) {
    let href = props.href || ''
    let linkProps = { ...props }
    for (const doc in tree) {
      if (href === tree[doc].sourceUrl && tree[doc].type !== 'href') {
        href = createDocPath(tree[doc], basePath)
        break
      }
    }
    // if url is a raw github, convert it to correct github url
    if (href.startsWith('http') && new URL(href).host === config.githubCloudProxyHost) {
      href = rawToGithubUrl(href)
    }

    // update external links to open in new tab
    if (href.startsWith('http')) {
      linkProps = {
        target: '_blank',
        rel: 'noopener noreferrer',
        ...linkProps,
      }
    }
    return React.createElement(Link || 'a', { ...linkProps, href }, linkProps.children)
  }
}

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function createHeadingRenderer(isContextualHelp) {
  return function(props) {
    const children = React.Children.toArray(props.children)
    const text = children.reduce(flatten, '')
    const slug = slugify(text)

    return React.createElement(
      props.node?.tagName || 'h3',
      { id: slug },
      <>
        {!isContextualHelp && (
          <a
            className={styles['heading-anchor']}
            id={slug}
            href={`${window.location.pathname}#${slug}`}
          >
            <Icon size='m' name='Link' />
          </a>
        )}
        {props.children}
      </>
    )
  }
}
